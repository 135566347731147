import Slider from "react-slick"

import logoLoader from '../../images/logoLoader.svg'
import { CoursesListItem } from "../CoursesListItem"
import { useEffect, useState } from "react"
import { CoursesList } from "../../classes"
import { SampleNextArrow, SamplePrevArrow } from "../../utils"
 
export const CoursesSlider = () => {
    const [load, setLoad] = useState(true)
    const [courses, setCourses] = useState(new CoursesList())

    useEffect(()=>{

        courses.getData().then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load)
        return(
            <div className="loader-block">
                <img src={logoLoader} />
                <p>Загрузка</p>
            </div>
        )

    return(
        <>
            <div className="cslider">
                <Slider {
                    ...{
                        dots: true, 
                        infinite: courses.list.length > 3 ? true : false, 
                        speed: 500, 
                        slidesToShow: 3, 
                        slidesToScroll: 1, 
                        arrows: courses.list.length > 3 ? true : false, 
                        nextArrow: <SampleNextArrow />, 
                        prevArrow: <SamplePrevArrow />,
                        responsive: [
                            {
                                breakpoint: 1300,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                }
                            },
                            {
                                breakpoint: 960,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    arrows: false
                                }
                            }
                        ]
                    }
                }>
                    {
                        courses.list.length ? (
                            courses.list.map((item, index) => {
                                return(
                                    <div>
                                        <CoursesListItem item={item} />
                                    </div>
                                )
                            })
                        ):""
                    }
                </Slider>
            </div>
        </>


    )
}