import { Link } from "react-router-dom"
import itemcourses from '../../images/item_courses.png'
import { hostImg } from "../../classes"

export const CoursesListItem = (props: any) => {
    return(
        <div className="courses-item">
            <div className="courses-image" style={{backgroundImage: `url('${hostImg}${props.item.image}')`}}></div>
            <div className="courses-description__wrapper">
                <h3 className="item-title">{props.item.title}</h3>
                <p className="mini-paragraf">{props.item.miniDesc}</p>
            </div>
            <div className="courses-item__wrapper">
                <div className="courses-price">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="courses-item__price-info">
                                Стоимость курса
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="courses-item__cost">
                                {
                                    props.item.cost == 0 ? (
                                        <>Бесплатно</>
                                    ):(
                                        <>{props.item.cost} ₽</>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="courses-item__button">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="courses-item__button-time">
                                Длительность<br/>
                                <span>{props.item.duration}</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <a href={`/courses/${props.item.pk}/`} className="light-button courses-item__button-detail">
                                Подробнее
                                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00005 6.36396H13.728M13.728 6.36396L8.40303 1.03901M13.728 6.36396L8.40303 11.6889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}