import axios from "axios"
import { host } from "../classes"
import arrowleft from '../images/arrow_left.svg'
import arrowright from '../images/arrow_right.svg'

export const ContactFormSender = async(form: {name: string, phone: string, email: string}) => {
    await axios.post(`${host}callback/`, {
        name: form.name,
        phone: form.phone,
        email: form.email,
    },{
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {}).catch(function (error) { }) 
}

export const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowright} 
            onClick={onClick}
        />
    );
}
  
export const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowleft} 
            onClick={onClick}
        />
    );
}