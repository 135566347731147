import doingwell2 from '../../../../images/doing-well2.svg'
import doingwell3 from '../../../../images/doing-well3.svg'
import doingwell4 from '../../../../images/doing-well4.svg'
import doingwell5 from '../../../../images/doing-well5.svg'

export const Advantages = () => {
    const advantages_list = [
        {
            image: doingwell3,
            title: "10 лет опыта",
            text: "Знаем все об уходе за маломобильными и паллиативными людьми"
        },
        {
            image: doingwell5,
            title: "Профессиональные преподаватели",
            text: "Действующие медицинские специалисты с многолетним практическим опытом"
        },
        {
            image: doingwell2,
            title: "Документы о квалификации",
            text: "По итогам обучения выдаем документы гособразца"
        },
        {
            image: doingwell4,
            title: "Гибкость",
            text: "Проводим корпоративное обучение персонала под ваш запрос"
        }
    ]

    return(
        <section className="advantages">
            <div className="content">
                <h2 className="block-title center">Почему выбирают нас</h2>
                <div className="row">
                    {
                        advantages_list.map((item, index) => (
                            <div className="col-md-6 col-xl-3" key={`advantages_${index}`}>
                                <div className="advantages-item__wrapper">
                                    <img src={item.image} height={86} />
                                    <h3 className='item-title'>{item.title}</h3>
                                    <p className='mini-paragraf'>{item.text}</p>
                                </div>
                            </div>
                        ))
                    } 
                </div>
            </div>
        </section>
    )
}