import { useContext, useEffect, useState } from "react"
import logoLoader from '../../images/logoLoader.svg'
import { FavouriteList } from "../../classes"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import { CoursesListItem } from "../../component/CoursesListItem"
import { CourseModule } from "../../component/CourseModule"
import { Helmet } from "react-helmet"

export const FavouritesPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [typeCourser, setTypeCourses] = useState(0)
    const [favourite, setFavourite] = useState(new FavouriteList)

    const GetData = (page: number, type = -1) => {
        setLoad(true)
        if(type != -1)
            setTypeCourses(type)
        else
            type = typeCourser 

        favourite.getData(page, type, type == 1 ? auth.user.favoriteModule : auth.user.favoriteCourses).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }
    
    useEffect(()=>{
        GetData(1)
    },[])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <Helmet>
                <title>Избранное - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="favourites">
                <div className="content">
                    <div className="account-type">
                        <button 
                            className={`light-button ${typeCourser == 0 ? "active":""}`}
                            onClick={()=>{
                                GetData(1, 0)
                            }}
                            style={{marginRight: 20}}
                        >
                            Курсы
                        </button>
                        <button
                            className={`light-button ${typeCourser == 1 ? "active":""}`}
                            onClick={()=>{
                                GetData(1, 1)
                            }}
                        >
                            Модули
                        </button>
                    </div>

                    <div className="row favourites-list">
                        {
                            typeCourser != 1 ? (
                                <>       
                                    {
                                        favourite.courseList.length ? (
                                            favourite.courseList.map((item, index) => {
                                                return(
                                                    <div className="col-md-6 col-xl-4 margin">
                                                        <CoursesListItem item={item} />
                                                    </div>
                                                )
                                            })
                                        ):(
                                            <p 
                                                style={
                                                    {
                                                        fontSize: 30,
                                                        textAlign: "center",
                                                        marginBottom: 10,
                                                        marginTop: "15px"
                                                    }
                                                }
                                            >
                                                Вы пока ничего не добавили в «Избранное»
                                            </p>
                                        )
                                    }
                                </>
                            ):(
                                <>       
                                    {
                                        favourite.moduleList.length ? (
                                            favourite.moduleList.map((item, index) => {
                                                return(
                                                    <div className="col-md-12 margin">
                                                        <CourseModule item={item} />
                                                    </div>
                                                )
                                            })
                                        ):(
                                            <p 
                                                style={
                                                    {
                                                        fontSize: 30,
                                                        textAlign: "center",
                                                        marginBottom: 10,
                                                        marginTop: "15px"
                                                    }
                                                }
                                            >
                                                Вы пока ничего не добавили в «Избранное»
                                            </p>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>

                    <Pagination 
                        page={favourite.page}
                        pageCount={favourite.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </section>
        </>
    )
}