import Slider from "react-slick";
import { SampleNextArrow, SamplePrevArrow } from "../../../../utils";

export const AboutProject = () => {
    const about_video = [
        "https://rutube.ru/play/embed/1e6959234b71ea4aefe1c20939c58cf7/",
        "https://rutube.ru/play/embed/0cfb681217c2e34d13fd2a46ad8c6591/"
    ]
    return(
        <section className="about-project">
            <div className="content">
                <div className="about-project__wrapper">
                    <h2 className="center block-title">Об образовательном портале</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="max-paragraf">
                                БФ «Живи сейчас» с 2015 года системно помогает людям с боковым амиотрофическим склерозом (БАС). 
                                За годы работы фонд получил глубокую экспертизу и сформировал сообщество специалистов по помощи тяжелобольным людям на разных стадиях болезни.
                            </p> 
                            <p className="max-paragraf">
                                Образовательный портал предназначен для распространения экспертных материалов и обучения медицинских работников, сиделок, специалистов социальной 
                                сферы и ухаживающих за тяжелобольными людьми, в том числе с паллиативным статусом.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <Slider {
                                ...{
                                    dots: true, 
                                    infinite: true, 
                                    speed: 500, 
                                    slidesToShow: 1, 
                                    slidesToScroll: 1, 
                                    nextArrow: <SampleNextArrow />, 
                                    prevArrow: <SamplePrevArrow />,
                                }
                            }>
                                {
                                    about_video.map((item, index) => (
                                        <div key={`about_video-${index}`}>
                                            <iframe 
                                                src={item}
                                                allow="clipboard-write; autoplay" 
                                            ></iframe>
                                        </div>
                                    ))
                                } 
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}