import { Link } from "react-router-dom";
import { CoursesSlider } from "../../../../component/CoursesSlider";

export const TopCourses = () => (
    <section className="our-courses">
        <div className="content">
            <h2 className="block-title center">Программы обучения</h2>
            <CoursesSlider />
            <div className="notification-block">
                <div className="row">
                    <div className="col-lg-9">
                        <p>
                            Обучающие курсы, лекции, тренинги, мастер-классы, программы для медициинских работников, специалистов социальной сферы, помощников по уходу.
                        </p>
                    </div>
                    <div className="col-lg-3">
                        <Link className="orange-button" to="/courses/">
                            Перейти в каталог
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
)