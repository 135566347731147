import Slider from "react-slick"
import { SampleNextArrow, SamplePrevArrow } from "../../../../utils"
import { Link } from "react-router-dom"

export const Banner = (props: any) => {
    return(
        <>
            <section className="banner">
                <div className="content">
                    <div className="banner-background-ring"></div>
                    <Slider {
                        ...{
                            dots: true, 
                            infinite: true, 
                            speed: 500, 
                            slidesToShow: 1, 
                            slidesToScroll: 1, 
                            arrows: true, 
                            nextArrow: <SampleNextArrow />, 
                            prevArrow: <SamplePrevArrow />,
                            responsive: [
                                {
                                    breakpoint: 960,
                                    settings: {
                                        arrows: false,
                                    }
                                },
                            ]
                        }
                    }>
                        {
                            props.slides ? (
                                props.slides.map((item: any, index: number) => (
                                    <div key={`banner_list-${index}`}>
                                        <div className="banner__wrapper">
                                            <div className="banner__content-wrapper">
                                                <div className="banner__content">
                                                    <h2 className="banner__content-title">{item.title}</h2>
                                                    <p className="banner__content-description">{item.desc}</p>
                                                    <Link className="orange-button" to={`${item.link}`}>Подробнее</Link>
                                                </div>
                                            </div>
                                            <div className="banner__image-wrapper">
                                                <div className="banner__image" style={{backgroundImage: `url('${item.image_link}')`}}></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ):""
                        } 
                    </Slider>      
                </div>
            </section>
        </>
    )
}