import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

import { hostImg } from "../../classes";

import { Banner } from "./component/Banner";
import { Questions } from "./component/Questions";
import { CorparativePpartners } from "./component/CorparativePpartners";
import { Partners } from "./component/Partners";
import { Reviews } from "./component/Reviews";
import { Advantages } from "./component/Advantages";
import { AboutProject } from "./component/AboutProject";
import { TopCourses } from "./component/TopCourses";

export const MainPage = () => {
    const [parts, setParts] = useState<[{
        id: number,
        sort: string,
        image: string,
        url: string
    }]>()
    const [slider, setSlider] = useState<[{
        title: string,
        desc: string,
        link: string,
        image_link: string
    }]>()


    const LoadPart = async () => {
        await axios.get(`${hostImg}get-slider/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            setSlider(res.data)
            console.log(res.data)
        }).catch(function (error) { 
            console.log(error)
        }) 
        
        await axios.get(`${hostImg}get-partners/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            setParts(res.data)
            console.log(res.data)
        }).catch(function (error) { 
            console.log(error)
        }) 
    }
    useEffect(()=>{
        LoadPart()
    },[])

    
    return(
        <>
            <Helmet>
                <title>Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <Banner slides={slider} />
            <TopCourses />
            <AboutProject />
            <Advantages />
            <Reviews />
            <Partners partner_list={parts} />
            <CorparativePpartners />
            <Questions />
        </>
    )
}