import { useState } from "react"
import { ReviewsSlider } from "../../../../component/ReviewsSlider"
import { Link } from "react-router-dom"
import { ReviewsPopup } from "../../../../component/ReviewsPopup"

export const Reviews = () => {
    const [openReview, setOpenReview] = useState(false)
    
    return(
        <>
            <section className="reviews">
                <div className="content">  
                    <h2 className="block-title center">
                        Отзывы
                        <span onClick={()=>{setOpenReview(true)}}>
                            Смотреть все отзывы
                            <svg onClick={()=>{alert("test")}} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10L10 1M10 1H2.46939M10 1V8.53061" stroke="#F26421" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </h2>
                    <ReviewsSlider />

                    <div className="notification-block">
                        <div className="row">
                            <div className="order-2 order-md-1 col-md-3">
                                <Link to="/courses/" className="orange-button">
                                    Выбрать курс
                                </Link>
                            </div>
                            <div className="order-1 order-md-2 col-md-9">
                                <p>
                                    Учебные материалы для специалистов здравоохранения, соцработников и людей, осуществляющих 
                                    лечение и уход за людьми с тяжелыми болезнями, в т.ч инкурабельными.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                openReview ? (
                    <ReviewsPopup setOpenReview={setOpenReview} />
                ):""
            }
        </>
    )
}