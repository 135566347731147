import { useEffect, useState } from "react"
import { CoursesList } from "../../classes"
import { CoursesListItem } from "../../component/CoursesListItem"
import { Pagination } from "../../component/Pagination"
import { Loader } from "../../component/Loader"
import { Helmet } from "react-helmet"

export const CatalogPage = () => {
    const [load, setLoad] = useState(true)
    const [courses, setCourses] = useState(new CoursesList(24))

    const queryParams = new URLSearchParams(window.location.search)
    const [search, setSearch] = useState(`${queryParams.get('s') ? queryParams.get('s') : ""}`)

    const GetData = (page: number) => {
        setLoad(true)
        courses.getData(page, search).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1)
    },[])
    
    if(load)
        return(
            <Loader />
        )
        
    return (
        <>
            <Helmet>
                <title>Каталог - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="catalog">
                <div className="content">
                    <h2 className="block-title">Каталог</h2>
                    <div className="row">
                        {
                            courses.list.length ? (
                                courses.list.map((item, index) => {
                                    return(
                                        <div className="col-md-6 col-xl-4 margin">
                                            <CoursesListItem item={item} />
                                        </div>
                                    )
                                })
                            ):""
                        }
                    </div>   
                    <Pagination 
                        page={courses.page}
                        pageCount={courses.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </section>
        </>
    )
}