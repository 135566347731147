import { Link } from 'react-router-dom'
import rev3 from '../../images/rev3.png'

export const ReviewsListItem = (props: any) => {
    return( 
        <div className={`col-md-${props.col}`}>
            <div className="reviews-item__wrapper">
                <div className="reviews-item__fio-wrapper row">
                    <div className="reviews-item__img">
                        <div style={{backgroundImage: `url('${rev3}')`}}></div>
                    </div>
                    <div className="reviews-item__fio">
                        <div className="grid">
                            <div>
                                {props.item.fio}<br/>
                                <span>
                                    <Link to={`/course/${props.item.course.pk}/`}>{props.item.course.title}</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="paragraf">{props.item.review}</p>
            </div>
        </div>
    )
}