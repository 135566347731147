import { Link, useParams } from "react-router-dom"
import { OpenerItem } from "../../../component/OpenerItem"
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Loader } from "../../../component/Loader"
import { PurchModule } from "../../../classes/purch"
import { Helmet } from "react-helmet"

export const PurchasedModulePage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const { course, pk } = useParams()
    const [module, setModule] = useState(new PurchModule({
        course: Number(course),
        pk: Number(pk),
        sort: 0,
        title: "",
        miniDesc: ""
    }))

    
    useEffect(()=>{
        setLoad(true)
        module.getData(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){ 
            alert("error")
        })
    },[])


    if(load)
        return(
            <Loader />
        )
        
    return(
        <>
            <Helmet>
                <title>Модули курса - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="purchased-course">
                <div className="content">
                    <Link 
                        to={`/account/${course}/`}
                        style={{
                            textDecoration: "none",
                            color: "#0c5da4",
                            fontSize: 18,
                            marginBottom: "30px",
                            display: "inline-block"
                        }}
                    >
                        {"< Назад"}
                    </Link>
                    <h2 className="block-title">{module.title}</h2>
                    <div className="purchased-course__modules">
                        <h3 className="item-title">
                            Уроки модуля
                        </h3>
                        <div className="purchased-course__wrapper">
                            {
                                module.lessons ? (
                                    module.lessons.map((item: any, index: number) => {
                                        return(
                                            <OpenerItem 
                                                title={
                                                    <div className="contacts-qw__title course-module__title">
                                                        <div className="row">
                                                            <div className="col-md-7" style={{cursor: "pointer"}}>
                                                                <div className="grid">
                                                                    <div>
                                                                        <span className="course-module__title-module">{
                                                                            module.completed?.indexOf(item.pk) !== -1 ? (<b>Урок пройден</b>):"Урок не пройден"
                                                                        }</span>
                                                                        {item.title} 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="grid">
                                                                    <div className="course-module__buy-info right"> 
                                                                        <div className="grid ">
                                                                            <Link 
                                                                                style={
                                                                                    {
                                                                                        color: "#0c5da4",
                                                                                        textDecoration: "none"
                                                                                    }
                                                                                }
                                                                                to={`/account/${course}/${pk}/${item.pk}/`}
                                                                            >
                                                                                Перейти к уроку
                                                                            </Link>
                                                                        </div>       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                } 
                                                desc={
                                                    <div className="questions-item__description mini-paragraf">
                                                        {item.miniDesc}
                                                    </div>
                                                }
                                            /> 
                                        )
                                    })
                                ):""
                            } 
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}