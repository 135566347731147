import { useContext, useEffect, useState } from 'react'
import close from '../../images/close.svg' 
import { GlobalContext } from '../../context/GlobalContext'

export const Testing = (props: any) => {
    const auth = useContext(GlobalContext)
    const [targetQuestion, setTargetQuestion] = useState(props.lessonTesting[0])
    const [targetIndex, setTargetIndex] = useState(0)
    const [selectList, setSelectList] = useState([""])
    const [resultList, setResultList] = useState<[[{flag: boolean, answer: string}]]>()
    const [resultItem, setResultItem] = useState(props.lessonTesting[0].answer.map(() => {
        return {
            flag: false,
            answer: ""
        }
    }))
 
    useEffect(()=>{  
        setResultItem(
            props.lessonTesting[targetIndex].answer.map(() => {
                return {
                    flag: false,
                    answer: ""
                }
            })
        )
        setSelectList(
            Shuffle(props.lessonTesting[targetIndex].answer.map((item: any, index: number) => {
                return item.answer
            }))
        )
        setTargetQuestion(props.lessonTesting[targetIndex])
    },[targetIndex])


    const Shuffle = function(array: [""]){
        let currentIndex = array.length,  randomIndex;
        while (currentIndex > 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const AddToList = () => {
        let array = resultList
        if(array){
            array?.push(resultItem)
            setResultList(array)
        }else
            setResultList([resultItem])
    }

    const SaveResult = () => {
        let score = 0
        let array: [{
            question: string
            right: string
            answer: string[]
        }]

        let result = resultList
        if(result){
            result?.push(resultItem)
        }else
            result = [resultItem]
        

        props.lessonTesting.forEach((item: any, index: number) => {
            let type = item.answer[0].text !== undefined ? "1":"2"
            let questionFlag = true
            let arrayItem = {
                question: item.questionText,
                right: "",
                answer: [""]
            }
            arrayItem.answer.slice()

            item.answer.forEach((item2: any, index2: number) => {
                if(type == "1"){
                    if(result![index][index2].flag != item2.answer){
                        questionFlag = false
                    }
                    arrayItem.answer.push(`${item2.answer ? "Да":"Нет"}. ${item2.text}`)
                }else{
                    if(result![index][index2].answer != item2.answer){
                        questionFlag = false
                    }
                    arrayItem.answer.push(`Вопрос: ${item2.question}. Ответ: ${item2.answer}`)
                }
            })
            arrayItem.right = questionFlag ? "Верно":"Ошибка"

            if(questionFlag)
                score += 1

                if(array)
                    array.push(arrayItem)
                else
                    array = [arrayItem]
                
        })
        props.setOpenPopup(true)
        let comp = score * (100 / props.lessonTesting.length)
        props.setOpenPopupText(`Ваш результат прохождения урока</br>${score} из ${props.lessonTesting.length}</br>${comp < 85 ? "Тестирование не пройдено":"Тестирование успешно пройдено"}`)
        props.lessonsItem.saveTesting(auth.user.userToken, JSON.stringify(array!), Math.round(comp)).then(function(response: any){})
    }

    
    return(
        <div className="popup-tests">
            <div>
                <div className="popup-info__close">
                    <img src={close} onClick={()=>{
                        props.setOpenPopupTest(false)
                    }} />
                </div>

                <div className="popup-tests__step-number right paragraf">
                    <b>Вопрос:</b> {targetIndex+1}/{props.lessonTesting.length}
                </div>

                <div className="popup-tests__step-content">
                    <div dangerouslySetInnerHTML={{ __html: targetQuestion.questionText }} />
                    <div className="popup-tests__step-questions">
                        <span>Варианты ответа</span>
                        {
                            targetQuestion.answer.map((item: any, index: number) => {
                                return(
                                    item.text !== undefined ? (
                                        resultItem ? (
                                            <div 
                                                className={`popup-tests__step-checkbox ${resultItem[index].flag == true ? "active":""}`}
                                                onClick={()=>{  
                                                    let array = resultItem.map((item: {flag: boolean, answer: string}) => {return item})
                                                    array[index].flag = !array[index].flag
                                                    setResultItem(array)
                                                }}
                                            >
                                                {item.text}
                                            </div>
                                        ):""
                                    ):(
                                        <div className="row popup-tests__step-select">
                                            <div className="col-md-6"> 
                                                {item.question}
                                            </div>
                                            <div className="col-md-6">
                                                <select onChange={(e)=>{     
                                                    let array = resultItem.map((item: {flag: boolean, answer: string}) => {return item})
                                                    array[index].answer = array[index].answer
                                                    setResultItem(array)                  
                                                }}>
                                                    <option value={"-"}>Не выбрано</option> 
                                                    {
                                                        selectList ? (
                                                            selectList.map((item: any, index: number) => {
                                                                return(
                                                                    <option value={item}>{item}</option> 
                                                                )
                                                            })      
                                                        ):""
                                                    } 
                                                </select>
                                            </div>
                                        </div>
                                    )
                                )
                            }) 
                        }
                    </div>
                </div>

                <div className="popup-tests__step-button right">
                    {
                        targetIndex+1 == props.lessonTesting.length ? (
                            <button
                                onClick={()=>{
                                    SaveResult()
                                    props.setOpenPopupTest(false)
                                }}
                                className='light-button'
                            >
                                Завершить тестирование
                            </button>
                        ):(
                            <button 
                                onClick={()=>{
                                    AddToList()
                                    setTargetIndex(targetIndex+1)
                                }}
                                className='light-button'
                            >
                                Далее
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}