import close from '../../images/close.svg'

export const PopupInfo = (props: any) => {
    return(
        <div className="popup-notification">
            <div>
                <div className="popup-notification__close">
                    <img src={close} onClick={()=>{
                        props.close(false)
                    }} />
                </div>
                <h3 className='item-title center'>{props.text}</h3>
            </div>
        </div>
    )
}