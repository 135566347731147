import { ChangeEvent, useState } from "react"
import { ContactFormSender } from "../../utils"
import { Loader } from "../Loader"

export const QuestionsForm = () => {
    const [load, setLoad] = useState(false)
    const [popupSubscribe, setPopupSubscribe] = useState(false)
    const [form, setForm] = useState({
        name: "",
        phone: "",
        email: "",
    })
    const [formSend, setFormSend] = useState(false)
    
    const SubmitForm = async(event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
            setLoad(true)
            ContactFormSender(form).then(function(response){
            setForm({
                name: "",
                phone: "",
                email: "",
            })
            setLoad(false)
            setFormSend(true)
        })
    }

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    if(load){
        <Loader />
    }

    return(
        <>
            <div className="questions-form">
                <h3 className="questions-form__title">Остались вопросы?</h3>
                <h4 className="questions-form__description">Наши специалисты проконсультируют вас</h4>
                {
                    !formSend ? (
                        <form className="row" onSubmit={SubmitForm}>
                            <div className="col-md-6 col-lg-3">
                                <label>
                                    <input type="text" placeholder="Имя" required name="name" value={form.name} onChange={changeHandler} />
                                </label>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label>
                                    <input type="text" placeholder="Номер телефона" required name="phone" value={form.phone} onChange={changeHandler} />
                                </label>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label>
                                    <input type="email" placeholder="Email" required name="email" value={form.email} onChange={changeHandler} />
                                </label>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <button className="orange-button">Заказать звонок</button>
                            </div>
                        </form>
                    ):(
                        <p className="questions-form__confirmed-text center">Заявка успешно отправлена</p>
                    )
                }
            </div>
        </>
    )
}