import Slider from "react-slick"
import { SampleNextArrow, SamplePrevArrow } from "../../../../utils"
import { hostImg } from "../../../../classes";

export const Partners = (props: any) => {
    return(
        <section className="partners">
            <div className="content">
                <h2 className="block-title center">С нами сотрудничают</h2>
                <Slider {
                    ...{
                        dots: true, 
                        infinite: true, 
                        speed: 500, 
                        slidesToShow: 5, 
                        slidesToScroll: 1, 
                        nextArrow: <SampleNextArrow />, 
                        prevArrow: <SamplePrevArrow />,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 1,
                                }
                            },
                            {
                                breakpoint: 960,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    arrows: false
                                }
                            },
                            {
                                breakpoint: 760,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    arrows: false
                                }
                            }
                        ]
                    }
                }>
                    {
                        props.partner_list ? (
                            props.partner_list.map((item: any, index: number) => {
                                return(
                                    <div>
                                        <div className="partners-wrapper">
                                            <div className="grid">
                                                <img src={`${hostImg}${item.image}`} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ):""
                    }
                </Slider>
            </div>
        </section>
    )
}