import { Link } from "react-router-dom";

export const CorparativePpartners = () => (
    <section>
        <div className="content">
            <div className="corparative-partners__wrapper">
                <div className="row">
                    <div className="col-lg-8">
                        <h2 className="block-title">Корпоративным партнерам</h2>
                        <p className="paragraf">Обучаем сотрудников медицинских и социальных учреждений на специальных условиях</p>
                    </div>
                    <div className="col-lg-4">
                        <div className="grid">
                            <div className="center">
                                <Link className="orange-button" to="/corporate/">Узнать подробнее</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)