import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Link, useParams } from "react-router-dom"
import { PurchCourse, PurchLesson } from "../../../classes/purch"
import { Loader } from "../../../component/Loader"
import { PopupInfo } from "../../../component/PopupInfo" 
import close from '../../../images/close.svg'
import { Testing } from "../../../component/Testing"
import { Helmet } from "react-helmet"

export const PurchasedLessonPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")
    const [load, setLoad] = useState(true)

    const [openPopupTest, setOpenPopupTest] = useState(false)
    const { course, module, pk } = useParams()

    const [testingList, setQuestionAns] = useState([{
        pk: 0,
        createDate: "",
        score: ""
    }])



    const [lesson, setLesson] = useState(new PurchLesson({
        course: Number(course),
        module: Number(module),
        pk: Number(pk),
        title: "",
        content: "",
        miniDesc: ""
    }))

    useEffect(()=>{
        setQuestionAns([])
        setLoad(true)
        lesson.getData(auth.user.userToken).then(function(response){
            lesson.loadTesting(auth.user.userToken).then(function(response2){
                setQuestionAns(response2)
                setLoad(false)
            })
            console.log(lesson.test)
        }).catch(function(error){ 
            alert("error")
        })
    },[])

    if(load)
        return(
            <Loader />
        )
        
    return(
        <>
            <Helmet>
                <title>Уроки модуля - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="lesson-item">
                <div className="content">
                    <Link 
                        to={`/account/${course}/${module}/`}
                        style={{
                            textDecoration: "none",
                            color: "#0c5da4",
                            fontSize: 18,
                            marginBottom: "30px",
                            display: "inline-block"
                        }}
                    >
                        {"< Назад"}
                    </Link>
                    <h2 className="block-title">{lesson.title}</h2>
                    <div className="lesson-item__content paragraf">
                        <div dangerouslySetInnerHTML={{ __html: lesson.content! }} />
                    </div>

                    <div className="lesson-item__buttons right">
                        <Link 
                            to={`/account/${course}/${module}/`}
                            style={{
                                textDecoration: "none",
                                color: "black"
                            }}
                        >
                            {`< К списку уроков`}
                        </Link>
                        {
                            lesson.test?.length ? (
                                <button
                                    className="light-button"
                                    onClick={()=>{
                                        setOpenPopupTest(true)
                                    }}
                                >
                                    Пройти тест
                                </button>
                            ):(
                                <button
                                    className="light-button"
                                    onClick={()=>{
                                        setOpenPopup(true)
                                        setOpenPopupText('Урок успешно пройден')
                                        lesson.completeLesson(auth.user.userToken).then(function(response){
                                            console.log(response)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}
                                >
                                    Завершить урок
                                </button>
                            )
                        }
                    </div>


                    {
                        lesson.test?.length ? (
                            <div className="lesson-item__testing">
                                <div>
                                    <table>
                                        <thead className="left">
                                            <tr>
                                                <th>ID</th>
                                                <th>Время тестирования</th>
                                                <th>Оценка</th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            {
                                                testingList.length ? (
                                                    testingList.map((item: any, index: number) => {
                                                        return(
                                                            <tr>
                                                                <td aria-label="ID">{item.pk}</td>
                                                                <td aria-label="Время тестирования">
                                                                    {item.createDate.split("T")[1].split(".")[0]} / 
                                                                    {item.createDate.split("T")[0]}
                                                                </td>
                                                                <td aria-label="Оценка">{item.score}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ):""
                    }
                </div>
            </section>

            {
                openPopupTest ? (
                    <Testing
                        setOpenPopupTest={setOpenPopupTest}
                        setOpenPopup={setOpenPopup}
                        setOpenPopupText={setOpenPopupText}
                        lessonTesting={lesson.test}
                        lessonsItem={lesson}
                    />
                ):""
            }

            {
                openPopup ? (
                    <div className="popup-notification">
                        <div>
                            <div className="popup-notification__close">
                                <img src={close} onClick={()=>{
                                    setLoad(true)
                                    lesson.loadTesting(auth.user.userToken).then(function(response){
                                        setLoad(false)
                                        setQuestionAns(response)
                                    })
                                    setOpenPopup(false)
                                }} />
                            </div>
                            <h3 className="center item-title" dangerouslySetInnerHTML={{ __html: openPopupText }} />

                            <Link 
                                className="light-button"
                                to={`/account/${course}/${module}/`}
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                Вернуться к списку уроков
                            </Link>
                        </div>
                    </div>

                ):""
            }
        </>
    )
}