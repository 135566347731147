import logoLoader from '../../images/logoLoader.svg'

export const Loader = () => {
    return(
        <div className='loader center'>
            <div className=''>
                <img src={logoLoader} />
                <div>Загрузка</div>
            </div>
        </div>
    )
}