import { useContext, useEffect, useState } from "react"
import { AccountForm } from "../../component/AccountForm"
import { Link } from "react-router-dom"
import vk from '../../images/vk.svg'
import tg from '../../images/tg.svg'
import { GlobalContext } from "../../context/GlobalContext" 
import { MyCoursesList } from "../../component/Purchased/MyCoursesList"
import { Helmet } from "react-helmet"

import menu01 from "../../images/menu_01.png"
import menu01active from "../../images/menu_01-active.png"

import menu02 from "../../images/menu_02.png"
import menu02active from "../../images/menu_02-active.png"

import menu03 from "../../images/menu_03.png"
import menu03active from "../../images/menu_03-active.png"

import menu04 from "../../images/menu_04.png"
import menu04active from "../../images/menu_04-active.png"
 
import menu05active from "../../images/menu_05-active.png"

export const AccountPage = () => {
    const auth = useContext(GlobalContext)

    function get(name: any){
        let sData = "" 
        try {
            let data = window.location.search.split('?')[1]
            let dataArray = data.split('&')
            dataArray.forEach((item) => {
                if(item.split("=")[0] == name){
                    sData = item.split("=")[1]
                }
            })
        }catch(err){ 
        }
        return sData
    }

    const [page, setPage] = useState(0)

    useEffect(()=>{
        if(get('p') == "course"){
            setPage(1)
        }
    },[])
    return(
        <>
            <Helmet>
                <title>Аккаунт - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="acccount">
                <div className="content">
                    <h2 
                        className="block-title"
                        style={{
                            marginBottom: 0
                        }}
                    >
                        Личный кабинет
                    </h2>
                    <p className="acccount-email-information">
                        <b>Email для авторизации:</b> {auth.user.email}
                    </p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="account-menu">
                                <div 
                                    className={`account-menu__item account01 ${page == 0 ? "active":""}`} 
                                    onClick={()=>{setPage(0)}}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${page == 0 ? menu01: menu01active})`
                                        }}
                                    >
                                        Мои данные
                                    </div>
                                </div>

                                <div 
                                    className={`account-menu__item account02 ${page == 1 ? "active":""}`} 
                                    onClick={()=>{setPage(1)}}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${page == 1 ? menu02: menu02active})`
                                        }}
                                    >
                                        Мои курсы
                                    </div>
                                </div>

                                <Link 
                                    to={'/favourites/'} 
                                    className={`account-menu__item account03 ${page == 2 ? "active":""}`} 
                                    onClick={()=>{setPage(2)}}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${page == 2 ? menu03: menu03active})`
                                        }}
                                    >
                                        Избранное
                                    </div>
                                </Link>

                                <div 
                                    className={`account-menu__item account04 ${page == 3 ? "active":""}`} 
                                    onClick={()=>{setPage(3)}}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${page == 3 ? menu04: menu04active})`
                                        }}
                                    >
                                        Поддержка
                                    </div>
                                </div>

                                <div 
                                    className="account-menu__item account05"
                                    onClick={()=>{
                                        auth.user.Logout()
                                        window.location.replace('/');
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${menu05active})`
                                        }}
                                    >
                                        Выход
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            {
                                page == 0 ? (
                                    <AccountForm />
                                ):""
                            }
                            {
                                page == 1 ? (
                                    <MyCoursesList />
                                ):""
                            }
                            {
                                page == 3 ? (
                                    <div className="account-help">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h2 className="block-title">Служба<br/>заботы</h2>
                                                <Link to={``} target="_blank" className="account-help__telegram-link">
                                                    <div>
                                                        Написать в чат
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="account-help__contact"> 
                                                    <div className="paragraf">
                                                        <span>Электронная почта</span><br/>
                                                        <Link to="mailto:kurs@als-info.ru">kurs@als-info.ru</Link>
                                                    </div>
                                                    <div className="paragraf">
                                                        <span>Телефон</span><br/>
                                                        <Link to="tel:+74959685626">+7 (495) 968-56-26</Link>
                                                    </div>
                                                    <div className="paragraf">
                                                        <span>Социальные сети</span><br/>
                                                        <Link to="https://vk.com/als.russia" target='_blank' style={{marginRight: "5px"}}>
                                                            <img src={vk} />
                                                        </Link>
                                                        <Link to="https://t.me/helpforpeaple" target="_blank" style={{marginLeft: "5px"}}>
                                                            <img src={tg} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ):""
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}